/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3",
    em: "em"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "Acknowledgements"
    }), "\n", _jsx(_components.p, {
      children: "Copyright © 2015-2023 The New York Public Library, Astor, Lenox, and Tilden Foundations"
    }), "\n", _jsxs(_components.p, {
      children: ["Licensed under the Apache License, Version 2.0 (the \"License\"); you may not use this file except in compliance with the License. You may obtain a copy of the License at\n", _jsx(_components.a, {
        href: "https://www.apache.org/licenses/LICENSE-2.0",
        children: "https://www.apache.org/licenses/LICENSE-2.0"
      }), " unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an \"AS IS\" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions and limitations under the License."]
    }), "\n", _jsx(_components.h2, {
      children: "Funding"
    }), "\n", _jsx(_components.p, {
      children: "This project is made possible in part by:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Institute of Museum and Library Services"
      }), "\n", _jsx(_components.li, {
        children: "The New York Public Library, Astor, Lenox, and Tilden Foundations"
      }), "\n", _jsx(_components.li, {
        children: "The Andrew W. Mellon Foundation"
      }), "\n", _jsx(_components.li, {
        children: "First Book"
      }), "\n", _jsx(_components.li, {
        children: "Clever"
      }), "\n", _jsx(_components.li, {
        children: "Baker & Taylor"
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      children: "Content Hosting and Services Provided By Baker & Taylor"
    }), "\n", _jsxs(_components.p, {
      children: ["B&T’s Axis360 services and Content are governed by its own license agreement, which can be found at ", _jsx(_components.a, {
        href: "https://nypl.axis360.baker-taylor.com/Terms?type=terms",
        children: "Axis360 End User License Agreement"
      }), ". You may not use the B&T services and Content except in compliance with this license."]
    }), "\n", _jsx(_components.h2, {
      children: "Education Partners"
    }), "\n", _jsx(_components.h3, {
      children: "First Book"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.a, {
        href: "https://firstbook.org/",
        children: "First Book"
      }), " partners with Open eBooks to provide free access to teachers and students."]
    }), "\n", _jsx(_components.h3, {
      children: "Clever"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.a, {
        href: "https://www.clever.com/",
        children: "Clever"
      }), " generously supports Open eBooks by providing educators nationwide with Single Sign-On (SSO) capabilities, removing barriers to access for students."]
    }), "\n", _jsx(_components.h2, {
      children: "Content Contributions"
    }), "\n", _jsx(_components.h3, {
      children: "Public Domain"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Eric Hellman - ", _jsx(_components.em, {
          children: "Unglue.it / Project Gitenberg"
        })]
      }), "\n", _jsxs(_components.li, {
        children: ["The contributing artist of ", _jsx(_components.em, {
          children: "The Creative Action Network"
        }), " at ", _jsx(_components.em, {
          children: "Recovering the Classics"
        })]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "Licensed Content"
    }), "\n", _jsxs(_components.p, {
      children: ["Thanks to the generous contributions of various publishers, we are able to provide access to free ebooks for kids. For a full list of publishers who provide licensed content, please visit our ", _jsx(_components.a, {
        href: "https://www.openebooks.org/partners",
        children: "Partners"
      }), " page."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
