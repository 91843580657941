/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    h3: "h3",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    ol: "ol",
    em: "em",
    h4: "h4",
    img: "img"
  }, _provideComponents(), props.components), {TOC, BackToTopButton} = _components;
  if (!BackToTopButton) _missingMdxReference("BackToTopButton", true);
  if (!TOC) _missingMdxReference("TOC", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "FAQs"
    }), "\n", _jsx(TOC, {}), "\n", _jsx(BackToTopButton, {}), "\n", _jsx(_components.h2, {
      children: "General Questions"
    }), "\n", _jsx(_components.h3, {
      children: "Why eBooks?"
    }), "\n", _jsx(_components.p, {
      children: "Open eBooks provides free ebooks and reading materials online to students. Students who can gain access through their qualifying school can use their mobile device, tablet, or laptop to download and read books at no cost."
    }), "\n", _jsx(_components.h3, {
      children: "How does it work?"
    }), "\n", _jsxs(_components.p, {
      children: ["To get started, an administrator, teacher or school librarian working within a qualifying school must first register with ", _jsx(_components.a, {
        href: "https://firstbook.org/",
        children: "First Book"
      }), ". Or if you are already an authorized ", _jsx(_components.a, {
        href: "https://clever.com/",
        children: "Clever user"
      }), ", you must enable access for qualifying classrooms."]
    }), "\n", _jsx(_components.p, {
      children: "Qualifying groups include:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Title 1 schoolwide programs"
      }), "\n", _jsx(_components.li, {
        children: "Libraries with an E-RATE of 90 or higher"
      }), "\n", _jsx(_components.li, {
        children: "Special education programs"
      }), "\n", _jsx(_components.li, {
        children: "Out of school programs serving communities in need"
      }), "\n", _jsx(_components.li, {
        children: "Summer meal sites"
      }), "\n", _jsx(_components.li, {
        children: "Programs serving military families"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Once registered, readers will have unlimited access to free books in the app. They can borrow up to 10 books at a time and replace each book with a new book as many times as they’d like."
    }), "\n", _jsx(_components.p, {
      children: "To get access to the virtual library of free ebooks for kids and teens, they—or an adult working with them—can download the Open eBooks app and enter credentials in one of two ways:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsx(_components.li, {
        children: "Enter an access code and PIN provided by First Book via a school administrator or librarian"
      }), "\n", _jsx(_components.li, {
        children: "Log in with a Clever login if the district has enabled Open eBooks usage for the student's school"
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "Who is eligible to access free books?"
    }), "\n", _jsx(_components.p, {
      children: "The library is available to students of participating Title 1 schools, special education classrooms, and children in military families, and more. An educator – either a teacher, program leader, or librarian – may sign up and request access codes through First Book. Title 1 districts that utilize Clever can ask their district administrator to enable Open eBooks in their dashboard."
    }), "\n", _jsx(_components.h3, {
      children: "Does Open eBooks help teach digital literacy?"
    }), "\n", _jsx(_components.p, {
      children: "While Open eBooks is not focused on teaching digital literacy skills, the application is a useful resource for a range of libraries and schools in their ongoing efforts to teach digital literacy and encourage reading and learning out of the classroom."
    }), "\n", _jsx(_components.h3, {
      children: "Is this a government program?"
    }), "\n", _jsx(_components.p, {
      children: "No, this is not a government program. Open eBooks operates as a partnership between the NYPL and First Book. Providing free books online to kids across the nation is made possible by generous contributions of service from Baker & Taylor and Clever, and content from top children’s book publishers."
    }), "\n", _jsx(_components.p, {
      children: "Open eBooks has been supported by grants from the Institute of Museum and Library Services, the Alfred P. Sloan Foundation and commitments from publishers. Although the program was launched in partnership with President Obama’s ConnectED initiative, no new federal budget line or staff was associated with the project."
    }), "\n", _jsx(BackToTopButton, {}), "\n", _jsx(_components.h2, {
      children: "Registration and eligibility"
    }), "\n", _jsx(_components.h3, {
      children: "How do I get started using First Book?"
    }), "\n", _jsxs(_components.p, {
      children: ["Any adult who works in a Title 1 (or Title 1 eligible) school, a library, or an out of school program that serves at least 70% of children from in-need communities can ", _jsx(_components.a, {
        href: "https://www.fbmarketplace.org/register/",
        children: "sign up"
      }), " with First Book. Once signed up, educators can request access codes for Open eBooks through the ", _jsx(_components.a, {
        href: "https://www.fbmarketplace.org/openebooks",
        children: "First Book Marketplace"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "If you are interested in accessing Open eBooks for your family and are not an educator, librarian or program lead, please direct your child’s educator to this site to request an Open eBooks access code on your child’s behalf."
    }), "\n", _jsx(_components.h3, {
      children: "How do I get started using Clever?"
    }), "\n", _jsxs(_components.p, {
      children: ["Districts using Clever can add Open eBooks to their dashboard, and students in Title 1 schools will have access to the app upon downloading it to their device. For more help, visit the ", _jsx(_components.a, {
        href: "https://support.clever.com/s/articles/230540587",
        children: "Clever Help Center"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "If your school offers Title 1 targeted assistance, or only a subset of the school qualifies for Open eBooks (e.g. a special education classroom), please register with First Book."
    }), "\n", _jsx(_components.h3, {
      children: "Can I register for my school district?"
    }), "\n", _jsx(_components.p, {
      children: "First Book does not offer qualification at the district level. District administrators should encourage school administrators to register their individual schools."
    }), "\n", _jsx(_components.p, {
      children: "Clever can be enabled at the district level. School districts using Clever will be able to grant access to qualifying schools; all district schools may not qualify."
    }), "\n", _jsx(_components.h3, {
      children: "Is Open eBooks available to home school students?"
    }), "\n", _jsx(_components.p, {
      children: "Access codes are not available to individuals; only a program administrator can register through First Book. We encourage home school students to register with their local library or an after-school program to receive codes."
    }), "\n", _jsx(_components.h3, {
      children: "Is Open eBooks available to special needs classrooms?"
    }), "\n", _jsxs(_components.p, {
      children: ["Open eBooks access codes are available to teachers or program administrators who primarily serve children with disabilities. You can learn more about obtaining access codes from ", _jsx(_components.a, {
        href: "https://firstbook.org/",
        children: "First Book"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      children: "If my school is not a Title 1 school, can I access Open eBooks?"
    }), "\n", _jsx(_components.p, {
      children: "Yes, Open eBooks is also available to:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Libraries with an E-RATE of 90 or higher"
      }), "\n", _jsx(_components.li, {
        children: "Special education programs"
      }), "\n", _jsx(_components.li, {
        children: "Out of school programs serving low-income communities"
      }), "\n", _jsx(_components.li, {
        children: "Summer meal sites, and"
      }), "\n", _jsx(_components.li, {
        children: "Programs serving military families."
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "Is Open eBooks available outside the U.S.?"
    }), "\n", _jsxs(_components.p, {
      children: ["Open eBooks is only available in the United States, United States territories, and on U.S. military bases. Our partner ", _jsx(_components.a, {
        href: "https://firstbook.org/",
        children: "First Book"
      }), " works on other initiatives throughout both the U.S. and Canada. For more information on digital literacy initiatives outside the US, visit our friends ", _jsx(_components.a, {
        href: "https://www.worldreader.org/",
        children: "Worldreader"
      }), ", ", _jsx(_components.a, {
        href: "https://libraryforall.org/",
        children: "Library fo All"
      }), ", and ", _jsx(_components.a, {
        href: "https://www.nabu.org/",
        children: "NABU.ORG"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      children: "Do I need to get one code per student? Or one code per device?"
    }), "\n", _jsx(_components.p, {
      children: "We encourage administrators to request codes for as many students as they have. Students can use their code or Clever login to log in on as many as 6 devices, so if they have access to devices at home, they may use their codes there too."
    }), "\n", _jsx(_components.h3, {
      children: "Do students need new logins with each school year?"
    }), "\n", _jsx(_components.p, {
      children: "If a student already has a First Book access code, he or she does not need a new one with each school year. If a student would like access to a different collection—for example an eighth grader entering high school—a qualifying teacher, librarian or administrator must request the new code through the First Book Marketplace."
    }), "\n", _jsx(_components.p, {
      children: "If a student uses Clever to access Open eBooks, his or her access will change once the student changes schools, and access will depend upon whether the new school uses Clever usage and whether it qualifies for Open eBooks.If a student logs in using Clever then changes schools, continued access will depend upon whether the new school uses Clever, and whether it qualifies for Open eBooks."
    }), "\n", _jsx(BackToTopButton, {}), "\n", _jsx(_components.h2, {
      children: "How Open eBooks works"
    }), "\n", _jsx(_components.h3, {
      children: "What if I don't own a smartphone or tablet, and don't have internet access?"
    }), "\n", _jsx(_components.p, {
      children: "If a student’s family does not own a device, they can access and borrow e-reading devices through their local school and library.You can also use your school or local library’s Wi-Fi to access Open eBooks and download the books in this program."
    }), "\n", _jsx(_components.h3, {
      children: "What platforms is Open eBooks available on?"
    }), "\n", _jsxs(_components.p, {
      children: ["Open eBooks is currently available as a mobile app for iOS on the ", _jsx(_components.a, {
        href: "https://apps.apple.com/us/app/open-ebooks/id1033669250",
        children: "App Store"
      }), " and for Android on the ", _jsx(_components.a, {
        href: "https://play.google.com/store/apps/details?id=org.nypl.labs.OpenEbooks.app",
        children: "Google Play Store"
      }), ", and through the website ", _jsx(_components.a, {
        href: "https://www.openebooks.org/",
        children: "openebooks.org"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      children: "Does “unlimited access” mean the books will be free of Digital Rights Management?"
    }), "\n", _jsx(_components.p, {
      children: "Readers can borrow up to 10 books at a time and replace each book with a new book as many times as they’d like. These ebooks include digital rights management software, which means that the book will be visible only to the student who is accessing it and cannot be shared."
    }), "\n", _jsx(_components.h3, {
      children: "How long can I borrow a book for?"
    }), "\n", _jsx(_components.p, {
      children: "You can borrow a book for up to 30 days."
    }), "\n", _jsx(_components.h3, {
      children: "How many books can I borrow at a time?"
    }), "\n", _jsx(_components.p, {
      children: "You can check out up to 10 books."
    }), "\n", _jsx(_components.h3, {
      children: "How can I get a new code?"
    }), "\n", _jsx(_components.p, {
      children: "If you have moved or changed schools, you should check with your new school’s librarian to receive a new code. Schools must qualify to be a part of the program, so you may find that your new school is not eligible to participate in Open eBooks."
    }), "\n", _jsx(BackToTopButton, {}), "\n", _jsx(_components.h2, {
      children: "User data and privacy"
    }), "\n", _jsx(_components.h3, {
      children: "How is users’ privacy protected and respected?"
    }), "\n", _jsxs(_components.p, {
      children: ["Open eBooks does not capture nor store any personally identifiable information about our app users. For more information, see the ", _jsx(_components.a, {
        href: "https://www.openebooks.org/privacy",
        children: "Open eBooks privacy policy"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["In order to access Open eBooks, users have to use one of our partners’ software to verify their eligibility. Please see the ", _jsx(_components.a, {
        href: "https://firstbook.org/about/privacy-compliance-policies/",
        children: "First Book privacy policy"
      }), " and the ", _jsx(_components.a, {
        href: "https://clever.com/trust/privacy",
        children: "Clever privacy policy"
      }), " for more on how our partners capture user information during the authentication process."]
    }), "\n", _jsx(_components.h3, {
      children: "Are individual readers’ activity tracked?"
    }), "\n", _jsxs(_components.p, {
      children: ["No, individual readers' activities are not tracked. Reading will be tracked ", _jsx(_components.em, {
        children: "anonymously and in the aggregate"
      }), ". A student’s privacy is paramount and the partners will work tirelessly to ensure that student privacy is maintained at all times."]
    }), "\n", _jsx(_components.h3, {
      children: "How do users report a problem?"
    }), "\n", _jsx(_components.h4, {
      children: "On web"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["To report an issue, ", _jsx(_components.a, {
          href: "https://www.openebooks.org/contact",
          children: "contact us"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.em, {
          children: "Please note:"
        }), " at this time, we do not support reporting problems directly from the reader experience. Stay tuned for updates on this."]
      }), "\n"]
    }), "\n", _jsx(_components.h4, {
      children: "On mobile"
    }), "\n", _jsx(_components.p, {
      children: "The Open eBooks mobile app allows you to report errors from inside the app. This is the fastest way for our tech team to resolve the issue."
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "To report an issue with a specific eBook, click on the eBook so you see its description. Then click \"Report a Problem,\" and select an option."
      }), "\n", _jsx(_components.li, {
        children: "To report an issue with the app, click \"More\" on the bottom menu, then \"Report an issue.\" Fill out the form and a tech support member will be in touch."
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "What are the terms and conditions of use for Open eBooks?"
    }), "\n", _jsxs(_components.p, {
      children: ["Please see our ", _jsx(_components.a, {
        href: "https://www.openebooks.org/privacy",
        children: "Privacy Policy"
      }), " for more information on our terms and conditions."]
    }), "\n", _jsx(BackToTopButton, {}), "\n", _jsx(_components.h2, {
      children: "Accessibility"
    }), "\n", _jsx(_components.h3, {
      children: "Does Open eBooks work for people who are print-disabled?"
    }), "\n", _jsxs(_components.p, {
      children: ["Yes. Open eBooks on mobile includes accessibility features. It is compatible with Apple and Android’s Text to Speech (TTS). Making this content accessible is a top priority and we continue to work to improve accessibility for Open eBooks and with our industry partners. Please ", _jsx(_components.a, {
        href: "https://www.openebooks.org/contact",
        children: "contact us"
      }), " if you have any concerns or issues about our accessibility."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.img, {
        src: "https://www.openebooks.org/img/OEB_FAQs_DyslexiaVisualAid.png",
        alt: "Dyslexia Visual Aid Image"
      })
    }), "\n", _jsx(_components.h3, {
      children: "How does it work for readers with Dyslexia?"
    }), "\n", _jsxs(_components.p, {
      children: ["Open eBooks supports ", _jsx(_components.a, {
        href: "https://opendyslexic.org/",
        children: "Open Dyslexic Font"
      }), " for those with Dyslexia."]
    }), "\n", _jsx(_components.p, {
      children: "To enable:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Open your eBook"
      }), "\n", _jsx(_components.li, {
        children: "Click the [Aa] button in the top right corner"
      }), "\n", _jsx(_components.li, {
        children: "Click the Open Dyslexic Font located in the top row on the far right"
      }), "\n"]
    }), "\n", _jsx(BackToTopButton, {}), "\n", _jsx(_components.h2, {
      children: "Collection and available ebooks"
    }), "\n", _jsx(_components.h3, {
      children: "How are eBooks selected?"
    }), "\n", _jsx(_components.p, {
      children: "Publishers have generously contributed thousands of titles to Open eBooks to ensure that Open eBooks provides books that speak to America’s diverse population and range of perspectives. The New York Public Library’s expert staffs review our collections for each age group."
    }), "\n", _jsx(_components.h3, {
      children: "What publishers committed to provide eBooks for students in in-need families?"
    }), "\n", _jsx(_components.p, {
      children: "Below is the list of the contributing publishers:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://www.bloomsbury.com/us/",
          children: "Bloomsbury"
        }), ": Providing unlimited access to over 1,000 of its most popular titles."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://www.candlewick.com/",
          children: "Candlewick"
        }), ": Providing unlimited access to all relevant children’s and young-adult eBook titles in their catalog."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://www.hachettebookgroup.com/",
          children: "Hachette"
        }), ": Offering access to a robust catalog of their popular and award-winning titles."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://www.harpercollins.com/",
          children: "HarperCollins"
        }), ": Providing a vast selection of their award-winning and popular titles."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://macmillan.com/",
          children: "Macmillan"
        }), ": Providing unlimited access to all of the K-12 age-appropriate titles in their catalog of approximately 2,500 books."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://www.nationalgeographic.com/books/article/our-program",
          children: "National Geographic"
        }), ": Providing unlimited access to all of their age-appropriate content."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://openroadintegratedmedia.com/",
          children: "Open Road"
        }), ": Providing unlimited access to their age-appropriate ebook titles."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://www.simonandschuster.com/",
          children: "Simon & Schuster"
        }), ": Providing access to their entire e-catalog of books for children ages 4-14, comprising 3,000 titles."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "Are the eBooks culturally diverse? Are there titles available in languages other than English?"
    }), "\n", _jsx(_components.p, {
      children: "Open eBooks is strongly committed to creating a diverse collection for all ages Open eBooks continually works with publisher partners to refresh the content and provide these new diverse titles to readers. The Open eBooks library contains a growing number of Spanish-language titles, and we plan to add more non-English languages."
    }), "\n", _jsx(_components.h3, {
      children: "Can I sort eBooks by reading level instead of grade level?"
    }), "\n", _jsx(_components.p, {
      children: "Open eBooks is currently organized into three collections: early grades, middle grades, and high school. Administrators can request access codes based on readers’ needs; there is also an option to request codes that give access to all three collections. However, there is currently no way to organize by reading level. Code administrators could give a reader access to a more appropriate collection based on reading level, but there is no way to filter material that may be too mature topically. This is left to the discretion of the teacher, librarian, administrator or parent, as with all reading material."
    }), "\n", _jsx(_components.h3, {
      children: "How can I see what my child is reading?"
    }), "\n", _jsx(_components.p, {
      children: "We do not have caregiver accounts that allow for browsing only access. Each collection is curated by subject matter expert librarians, focused specifically on each age group, ensuring the content is appropriate for readers."
    }), "\n", _jsx(_components.h3, {
      children: "More questions?"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.a, {
        href: "https://www.openebooks.org/contact",
        children: "Contact us"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
