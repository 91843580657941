/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {CONTACT_EMAIL} from "../constants";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "User Agreement"
    }), "\n", _jsx(_components.p, {
      children: "Last Updated: August 2023"
    }), "\n", _jsx(_components.p, {
      children: "This End User License Agreement (“EULA”) is a legal agreement made between The New York Public Library, Astor, Lenox, and Tilden Foundations (“NYPL”) and you (“you” and “your”) as a licensee and user of NYPL Open eBooks’ machine-readable object code (“the Software”), an eReader application for mobile devices developed and distributed by NYPL for the purpose of providing qualified low-income children free access to ebooks (“Content”) as described at https://www.openebooks.org/about. The Open eBooks initiative has been spearheaded by NYPL, Clever (“Clever”) and First Book (“FB”) (collectively, the “Open eBooks Parties” and each individually an “Open eBooks Party”) in conjunction with and support from The White House, The United States Institute of Museum and Library Services (“IMLS”), book distributor Baker & Taylor Inc. (“B&T”) and Content publishers. By clicking the \"Accept\" button associated with this EULA or using the Software, you are confirming your acceptance of the Software and are agreeing to become bound by this EULA. If you do not agree to be bound by these terms, then you may not use the Software. Failure to abide by the terms of this EULA may result in termination of your access to the Software, Content, and any related websites or applications controlled by any of the Open eBooks Parties. Notwithstanding the foregoing, software having a separate end user license agreement shall be covered by such a separate end user license agreement and shall be expressly excluded from the definition of Software."
    }), "\n", _jsx(_components.h2, {
      children: "I. Grant of License to Software"
    }), "\n", _jsx(_components.p, {
      children: "A. NYPL grants you a non-exclusive, non-assignable, non-transferable, limited license to the Software in machine-readable code only for your personal, non-commercial use. The Software is licensed as a single product, and not sold."
    }), "\n", _jsx(_components.p, {
      children: "B. You shall not:"
    }), "\n", _jsx(_components.p, {
      children: "(1) copy, rent, lease, sell, repair, transfer, assign, sublicense, disassemble, circumvent, reverse engineer or decompile, modify or alter the Software including, but not limited to, translating or creating derivative works, under any circumstances; (2) separate the Software into its component parts for independent use; (3) remove any proprietary notices and/or labels on or in the Software; and/or (4) use the Software for any commercial or illegal purpose. Your use of Content must also comply at all times with the fair use privilege under the U.S. copyright laws (see, e.g., 17 U.S.C. Section 107)."
    }), "\n", _jsxs(_components.p, {
      children: ["Proposal:\nC. Content Hosting and Services Provided By Baker & Taylor. B&T’s Axis360 services and Content are governed by its own license agreement, which can be found at: ", _jsx(_components.a, {
        href: "https://nypl.axis360.baker-taylor.com/Terms?type=terms",
        children: "Axis360"
      }), ". You may not use the B&T services and Content except in compliance with this license."]
    }), "\n", _jsx(_components.h2, {
      children: "II. Grant of License to eBook Content Accessed Through the Software"
    }), "\n", _jsx(_components.p, {
      children: "A. Multiple Providers; Multiple Licenses. The Open eBook Parties may provide their own Content, but have also arranged for you to access the services and Content contributed by publishers, as well as Content from other suppliers (collectively, the “Third Party Content Suppliers”). The Content provided by each of the Third Party Content Suppliers is governed by the applicable Third Party Content Supplier’s own terms and conditions, all of which you must agree to and comply with in order to use the Software and access the Content. The Open eBook Parties may add new Third Party Content Suppliers from time to time, with or without notice on its website, and you agree that you will be bound by the terms and conditions of any of these additional Third Party Content Suppliers if you use Content from them. The Settings section of the Open eBooks app directs you to the terms of some of the different Third Party Content Suppliers, which set out the conditions under which you are licensed to use their Content."
    }), "\n", _jsx(_components.p, {
      children: "B. Responsibility for Terms. You are solely responsible for knowing the terms and conditions of the different Third Party Content Suppliers in order to use Content from them. Any information provided in this section about the terms of Third Party Content Suppliers is provided for your convenience only, and you acknowledge that any information provided in this section may not reflect the current or accurate terms governing the Third Party Content Suppliers. You are encouraged to check regularly for updates to the terms of the Third Party Content Suppliers."
    }), "\n", _jsx(_components.p, {
      children: "C. Independent Transactions. The Open eBook Parties make no representations and shall have no liability or obligation whatsoever in relation to your use of any Content accessed through the Software. Any transaction completed with any of the Third Party Content Suppliers through the Software, and any dispute concerning the terms of service of any of the Third Party Content Suppliers, is between you and the relevant third party, and not the Open eBook Parties."
    }), "\n", _jsx(_components.p, {
      children: "D. Violation of any of the above restrictions may result in a termination of your ability to access the Content."
    }), "\n", _jsx(_components.h2, {
      children: "III. Ownership"
    }), "\n", _jsx(_components.p, {
      children: "A. Copyright"
    }), "\n", _jsx(_components.p, {
      children: "Copyright laws and international copyright treaties, as well as other intellectual property laws and treaties, protect the Software and Content. The Software and Content is licensed, not sold and no ownership rights in the Software and Content are transferred to you at any time. You acknowledge and warrant that nothing in this EULA gives you any right, title or interest in the Software and Content."
    }), "\n", _jsx(_components.p, {
      children: "B. Use of the Software with Copyrighted Material"
    }), "\n", _jsx(_components.p, {
      children: "The Software is capable of being used by you to store, process and use Content created by you and third parties. Such Content may be protected by copyright, other intellectual property laws, and/or agreements. You agree to use the Software only in compliance with all such laws and agreements that apply to such Content. You agree that the NYPL may take appropriate measures to protect the copyright of Content stored, processed or used by the Software. Such measures include, but are not limited to, counting the frequency of your backup and restoration through certain Software features, refusal to accept your request to enable restoration of data, and termination of this EULA in the event of your illegitimate use of the Software or Content."
    }), "\n", _jsx(_components.h2, {
      children: "IV. Trademarks"
    }), "\n", _jsx(_components.p, {
      children: "All text, graphics, user interfaces, visual interfaces, photographs, trademarks (whether or not registered), logos, sounds, music, and artwork (collectively, “Marks”), including but not limited to the design, structure, selection, coordination, expression, “look and feel” and arrangement of such Marks, contained on the Software is owned, controlled or licensed by or to Open eBooks Parties and their suppliers, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws. The Marks of Open eBooks Parties and suppliers may not be used without prior written consent of the relevant Open eBooks Parties or suppliers, as the case may be."
    }), "\n", _jsx(_components.h2, {
      children: "V. Age of Users"
    }), "\n", _jsx(_components.p, {
      children: "If you are under 18 years of age, you must obtain permission from a parent, legal guardian or school official in order to accept the EULA for the Software. In compliance with the Children's Online Privacy Protection Act (\"COPPA\"), as such may be amended from time to time, if you are under the age of 13, you are asked not to provide any information to us either through downloading any Software, using the Software, participating in or entering any promotions, contests or sweepstakes related to the Software, or through any other activity. If we have any knowledge that you are under 13 years of age and without express parental consent, any information you submit will likely not be retained by us, as described in Open eBooks’ Privacy Policy (https://www.openebooks.org/privacy). Children using the Internet require special protection, and parents, guardians and school officials should explain Internet safety to their children. Parents, guardians and school officials are urged to spend time online\nwith their children to become familiar with the types of Content available on the Software and the Internet in general. Control tools are available from online services and software manufacturers to help create a safer environment for children."
    }), "\n", _jsx(_components.h2, {
      children: "VI. Feedback; Usage Data"
    }), "\n", _jsx(_components.p, {
      children: "Any feedback or suggestions regarding the Software provided by you (“Suggestions”), and any related intellectual property rights, shall be and hereby is non-exclusively licensed to NYPL by you on a perpetual, royalty-free, worldwide, and irrevocable basis. You acknowledge and understand that in connection with your use of the Software and/or Content (“Usage Data”) certain information regarding your use of the Software may be transmitted to the Open eBook Parties and Third Party Content Suppliers. This Usage Data may be collected and used by the Open eBook Parties and Third Party Content Suppliers to provide support services to you, to better tailor the Software to your use, to improve the Software generally, and for research, development, and fundraising purposes. You hereby consent to such activities. All Usage Data collection is subject to Open eBooks’ Privacy Policy (https://www.openebooks.org/privacy)."
    }), "\n", _jsx(_components.h2, {
      children: "VII. Privacy Policy"
    }), "\n", _jsx(_components.p, {
      children: "For information about data protection and collection practices, please read Open eBooks’ Privacy Policy (https://www.openebooks.org/privacy), which is incorporated herein by reference. You agree to the use of your data in accordance with the Open eBooks Privacy Policy."
    }), "\n", _jsx(_components.h2, {
      children: "VIII. No Warranties; No Support"
    }), "\n", _jsx(_components.p, {
      children: "The Software is provided \"as is'' without any warranty of any kind. Except to the extent required by applicable law, the Open eBook Parties and Third Party Content Suppliers disclaim all warranties, representations, conditions, and duties, whether express or implied, regarding the Software and/or Content, including, without limitation, any and all implied warranties of merchantability, accuracy, results of use, reliability, fitness for a particular purpose, title, and non-infringement of Third Party rights. Further, the Open eBook Parties and Third Party Content Suppliers disclaim any warranty that Your use of the Software and/or Content will be uninterrupted, secure, timely or error free. You acknowledge and agree that this EULA does not entitle You to any support for the Software and/or Content. No advice or information, whether oral or in writing, obtained by You from the Open eBook Parties or Third Party Content Suppliers will create any warranty not expressly stated in this EULA."
    }), "\n", _jsx(_components.h2, {
      children: "IX. Limitation of Liability"
    }), "\n", _jsx(_components.p, {
      children: "The Software is being provided by NYPL free of charge. Accordingly, You agree that each of the Open eBooks Parties and the Third Party Content Suppliers shall have no liability arising from or related to Your access to and/or use of the Software and/or Content (or the Open eBooks Parties' suspension or termination of such access and/or use). Regardless of whether any remedy set forth herein fails of its essential purpose or otherwise, and except for bodily injury, in no event shall the Open eBooks Parties or their officers, trustees, agents, employees, the Third Party Content Suppliers or any of the Open eBooks Parties be liable to you or to any third party under any tort, contract, negligence, strict liability or other legal or equitable theory for any lost profits, lost or corrupted data, computer failure or malfunction, interruption of business, or other special, indirect, incidental, punitive, or consequential damages of any kind arising out of the use or inability to use the Software and/or Content, even if the Third Party Content Suppliers or any of the Open eBooks Parties have been advised of the possibility of such loss or damages and whether or not such loss or damages are foreseeable."
    }), "\n", _jsx(_components.h2, {
      children: "X. Indemnification"
    }), "\n", _jsx(_components.p, {
      children: "You agree to indemnify, defend, and hold harmless the Open eBook Parties and their officers, trustees, employees, agents, and their suppliers from any claim or demand, including reasonable attorneys' fees, arising from or in any way related to your use of the Software and/or Content, and/or violation of this EULA. The Open eBook Parties shall provide you with written notice of any such claim or demand. The Open eBook Parties and Third Party Content Suppliers reserve the right to assume the exclusive defense and control of any matter subject to indemnification by you, which shall not excuse your indemnity obligations. In the event of any settlement of an action, you agree to obtain the relevant Open eBook Parties’ prior written consent to the settlement."
    }), "\n", _jsx(_components.h2, {
      children: "XI. Digital Millennium Copyright Act"
    }), "\n", _jsxs(_components.p, {
      children: ["The Digital Millennium Copyright Act of 1998 (the \"DMCA\") provides recourse for copyright owners, if you believe in good faith that Content on the Software infringes your copyright, you (or your agent) may send notice ", _jsx("a", {
        href: `mailto:${CONTACT_EMAIL}`,
        children: CONTACT_EMAIL
      }), ". requesting that the material be removed or access to it blocked."]
    }), "\n", _jsx(_components.h2, {
      children: "XII. U.S. Government Rights"
    }), "\n", _jsx(_components.p, {
      children: "A. The Software licensed hereunder is provided with restricted rights applicable to private and public licenses alike. Without limiting the foregoing, any use, duplication, modification, reproduction, operation, performance, release or disclosure of the Software by the U.S. Government is subject to restrictions as set forth in this EULA and as provided in DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS 252.227-7013 (c)(1)(ii)(OCT 1988), FAR 12.212(a)(1995), FAR 52.227-19, or FAR 52.227-14, as applicable."
    }), "\n", _jsx(_components.p, {
      children: "B. You agree that by using the Software and accessing Content that: (i) you do not reside in a country subject to embargo or export controls by the U.S. Government; (ii) you are not on the List of Denied Persons as published by the U.S. government; and (iii) you will not use the Software or any Content for any illegal purpose."
    }), "\n", _jsx(_components.h2, {
      children: "XIII. General Terms"
    }), "\n", _jsx(_components.p, {
      children: "A. Governing Law. This EULA shall be governed, interpreted, and enforced by the laws of the State of New York. Any legal action brought involving the Software, Content, and/or EULA shall be brought only in the courts of the State of New York, in the County of New York, or in the federal courts located in such state (and county). Both you and the Open eBook Parties submit to venue and jurisdiction in these courts. In the event that an action or claim arises outside of the exclusive jurisdiction specified herein which names any of the Open eBook Parties as a party, the relevant Open eBook Parties and you specifically agree to initiate, consent to and/or cooperate with any and all efforts to remove the matter to the exclusive jurisdiction named herein, or otherwise take any and all reasonable actions to achieve the objectives of this provision."
    }), "\n", _jsx(_components.p, {
      children: "B. Entire Agreement. This EULA constitutes the entire agreement and understanding between you and NYPL and supersedes all prior and contemporaneous agreements, understandings, negotiations and proposals, oral or written."
    }), "\n", _jsx(_components.p, {
      children: "C. Severability. In the event that a court of competent jurisdiction determines that any portion of this EULA is unenforceable, said unenforceability shall not affect any other provision of this EULA."
    }), "\n", _jsx(_components.h2, {
      children: "XIV. Modification of the EULA and Software"
    }), "\n", _jsx(_components.p, {
      children: "A. NYPL may modify any of the terms and conditions contained in this EULA at any time and in NYPL’s sole discretion."
    }), "\n", _jsx(_components.p, {
      children: "B. NYPL may release subsequent versions and/or updated versions of the Software and require you to use the most current version by releasing an automatic update and/or disabling the previous version."
    }), "\n", _jsx(_components.p, {
      children: "C. NYPL may conduct maintenance on, stop providing, and/or change the method of access to the Software, and/or Content at any time, with or without notice to you."
    }), "\n", _jsx(_components.p, {
      children: "D. If any modification is unacceptable to you, your sole recourse is to terminate this EULA by discontinuing use of the Software and removing it from your mobile device. Your continued usage of the Software and/or Content following NYPL’s modification constitutes Your irrevocable and binding acceptance of the change."
    }), "\n", _jsx(_components.h2, {
      children: "XV. Termination"
    }), "\n", _jsx(_components.p, {
      children: "A. The term of this EULA (\"Term\") shall continue indefinitely unless terminated by NYPL or you in accordance with the provisions herein."
    }), "\n", _jsx(_components.p, {
      children: "B. NYPL reserves the right, in its sole discretion (for any reason or for no reason) and at any time without notice to you, to modify, suspend or discontinue the Software and/or Content, and/or suspend or terminate your rights under this EULA to access and/or use the Software and/or Content. You may terminate this EULA by discontinuing use of the Software and removing it from your mobile device."
    }), "\n", _jsx(_components.p, {
      children: "C. Upon termination of this EULA, you shall promptly delete and remove any and all Software and Content in your possession or under your control. Any termination of access to the Software and/or Content will also immediately terminate any and all licenses granted to you hereunder. All disclaimers of warranties and limitation of liability set forth in this EULA shall survive any termination of this EULA."
    }), "\n", _jsx(_components.h2, {
      children: "XVI. Compliance"
    }), "\n", _jsx(_components.p, {
      children: "NYPL reserves the right to take steps NYPL believes are reasonably necessary or appropriate to enforce and/or verify compliance with any part of this EULA. You acknowledge and agree that the Open eBook Parties may preserve any transmittal or communication by you with the Open eBook Parties or any third party through the Software or otherwise to this end. You are advised that any library record or other information collected by NYPL, pursuant to New York CPLR §4509, subject to disclosure upon subpoena, court order, or as otherwise authorized by applicable law."
    }), "\n", _jsx(_components.h2, {
      children: "XVII. Waiver"
    }), "\n", _jsx(_components.p, {
      children: "The failure of the Open eBook Parties to exercise or enforce any right or provision of this EULA shall not operate as a waiver of such right or provision, nor shall any course of conduct between the Open eBook Parties and you or any other party be deemed to modify any provision of this EULA."
    }), "\n", _jsx(_components.h2, {
      children: "XVIII. Automatic Update Feature"
    }), "\n", _jsx(_components.p, {
      children: "From time to time, NYPL or third parties may automatically update or otherwise modify the Software, including, but not limited to, for purposes of enhancement of security functions, error correction and improvement of functions, at such time as you interact with NYPL's or third parties' servers, or otherwise. Such updates or modifications may delete or change the nature of features or other aspects of the Software, including functions you may rely upon. You hereby agree that such activities may occur at NYPL’s sole discretion and that NYPL may condition continued use of the Software upon your complete installation or acceptance of such update or modifications. Any updates/modifications shall be deemed to be, and shall constitute part of, the Software for purposes of this EULA. By acceptance of this EULA, you consent to such update/modification."
    }), "\n", _jsx(_components.h2, {
      children: "XIX. Third Party Beneficiaries"
    }), "\n", _jsx(_components.p, {
      children: "Each Open eBooks Party and Third Party Content Supplier is an express intended third party beneficiary of, and shall have the right to enforce, each provision of this EULA to the extent applicable to such party."
    }), "\n", _jsx(_components.h2, {
      children: "XX. Contact"
    }), "\n", _jsxs(_components.p, {
      children: ["All questions concerning this EULA, Software and/or Content shall be directed to ", _jsx("a", {
        href: `mailto:${CONTACT_EMAIL}`,
        children: CONTACT_EMAIL
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
