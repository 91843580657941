/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    a: "a",
    h3: "h3",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "Sign In Troubleshooting"
    }), "\n", _jsx(_components.p, {
      children: "Having issues signing in to your Open eBooks account? See our common troubleshooting steps below."
    }), "\n", _jsx(_components.p, {
      children: "Please note: for your privacy and protection, Open eBooks does not have access to your account and cannot reset your PIN or password. See below for forgotten or lost credentials."
    }), "\n", _jsx(_components.h2, {
      children: "Clever Users"
    }), "\n", _jsxs(_components.p, {
      children: ["If you use Clever to sign in to your Open eBooks account, Clever has a ", _jsx(_components.a, {
        href: "https://support.clever.com/hc/s/articles/115002717307",
        children: "dedicated help page"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      children: "Common Fixes"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Make sure you type out your district code, username and password"
      }), "\n", _jsxs(_components.li, {
        children: ["Do ", _jsx(_components.strong, {
          children: "not"
        }), " copy-paste your access code and PIN number into the website"]
      }), "\n", _jsxs(_components.li, {
        children: ["Still having trouble? ", _jsx(_components.a, {
          href: "https://support.clever.com/",
          children: "Get in touch with Clever"
        })]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "Forgotten or Lost Username and/or Password"
    }), "\n", _jsx(_components.p, {
      children: "For student privacy, Open eBooks does not manage your Clever account and we cannot retrieve your username or reset your password."
    }), "\n", _jsx(_components.p, {
      children: "Please refer to one of the resources below for dedicated Clever help, including forgotten or lost username and passwords:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://support.clever.com/hc/s/articles/115002717307",
          children: "For Students: Troubleshooting - Logging in to Clever"
        })
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://support.clever.com/hc/s/articles/222933888",
          children: "For Teachers: Troubleshooting - Student logins"
        })
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://support.clever.com/hc/s/articles/360039685112",
          children: "For Teachers: Troubleshooting - Teacher logins"
        })
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Still having trouble? Visit the ", _jsx(_components.a, {
        href: "https://support.clever.com/",
        children: "Clever help center"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      children: "First Book Users"
    }), "\n", _jsx(_components.h3, {
      children: "Common Fixes"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Make sure you type out your access code and PIN number"
      }), "\n", _jsxs(_components.li, {
        children: ["Do ", _jsx(_components.strong, {
          children: "not"
        }), " copy-paste your access code and PIN number into the website"]
      }), "\n", _jsx(_components.li, {
        children: "Double check 0 and O to ensure your access code does not have incorrect characters"
      }), "\n", _jsx(_components.li, {
        children: "Access code are alphanumeric, they include both letters and numbers"
      }), "\n", _jsx(_components.li, {
        children: "Your PIN number should be 4 characters only"
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "Forgotten or Lost Access Code and/or PIN Number"
    }), "\n", _jsx(_components.p, {
      children: "For student privacy, Open eBooks does not manage First Book access codes or PIN numbers, and we cannot retrieve an access code for a student or reset their PIN number."
    }), "\n", _jsx(_components.p, {
      children: "Students who have forgotten their access code or PIN number should reach out to their teacher, school librarian or First Book administrator. Usually, this is the educator who provided them with their access code the first time."
    }), "\n", _jsxs(_components.p, {
      children: ["To reach out to First Book for help with your credentials, contact ", _jsx(_components.a, {
        href: "mailto:help@firstbook.org",
        children: "help@firstbook.org"
      }), " or by phone at 866-732-3669."]
    }), "\n", _jsx(_components.h2, {
      children: "Contact Us"
    }), "\n", _jsxs(_components.p, {
      children: ["Still having trouble? Other questions? ", _jsx(_components.a, {
        href: "https://www.openebooks.org/contact",
        children: "Contact us"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
