/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    ul: "ul"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "Privacy Policy"
    }), "\n", _jsx(_components.h2, {
      children: "Our Privacy Promise"
    }), "\n", _jsx(_components.p, {
      children: "Student privacy is paramount to Open eBooks. Our promise is to create a private and safe reading environment, and that includes protection of data and overall privacy."
    }), "\n", _jsx(_components.p, {
      children: "Open eBooks does not collect or store any personally identifiable information (PII) about any student users."
    }), "\n", _jsx(_components.h2, {
      children: "Student Privacy & the Open eBooks Apps"
    }), "\n", _jsx(_components.p, {
      children: "In order to become an Open eBooks user, a child will need one of 2 things: 1) a username and password from Clever, or 2) a registration code and Pin from First Book."
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["\n", _jsxs(_components.p, {
          children: ["Clever partners directly with school districts. Clever is a single sign on (SSO) partner that gives schools access to applications. Clever provides username and passwords to students, which they can then use to sign in to different applications—one of them being Open eBooks. In the process of registering to use Clever, schools may opt to share some personally identifiable information about students with Clever (please see ", _jsx(_components.a, {
            href: "https://clever.com/trust/privacy/policy",
            children: "Clever’s Privacy Policy"
          }), ")."]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsxs(_components.p, {
          children: ["First Book will distribute codes to adults (such as a teacher or school librarian) who serve populations of young people. In order to receive codes, those adults must first register with First Book, which will collect some personally identifiable information about them as part of the registration process (please see ", _jsx(_components.a, {
            href: "https://firstbook.org/about/privacy-compliance-policies/",
            children: "First Book’s Privacy Policy"
          }), "). Those adults will then distribute codes to students. When possible, teachers and other adults serving young people should check with parents to ensure they understand and approve of their child’s use of Open eBooks. Children using the app will not need to provide any personally identifiable information,including email addresses. They will simply input the code to unlock access to the Open eBooks collection."]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "While some aggregate data will be kept on the overall use and behavior of readers, we will not associate any individual reader’s data with their identity."
    }), "\n", _jsx(_components.h2, {
      children: "Partner Privacy Policies"
    }), "\n", _jsx(_components.p, {
      children: "For more information about our partner’s privacy policies, please visit the following pages:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://www.baker-taylor.com/policy/privacy-policy",
          children: "Baker & Taylor’s Privacy Policy"
        })
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://firstbook.org/about/privacy-compliance-policies/",
          children: "First Book’s Privacy Policy"
        })
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://clever.com/trust/privacy/policy",
          children: "Clever’s Privacy Policy"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
